import React from "react";
import { Grid, Typography, IconButton, Box } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const CartItem = ({ CartData, BugGenerator }) => {
  const [itemQuantityInCart, setItemQuantityInCart] = CartData.state;
  const name = CartData.name;
  const onTrashButtonClicked = BugGenerator.wrapWithBugs(
    name + ".onTrashButtonClicked",
    () => {
      setItemQuantityInCart(0);
    }
  );

  return (
    <div veelooka={CartData.name}>
      <Grid
        container
        alignItems="center"
        spacing={1}
        style={{ marginBottom: "5px" }}
      >
        <Grid item xs={3}>
          <Typography variant="h6">{CartData.name}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography
            style={{
              background: "#eef4f7",
              border: "1px solid #42a4dc",
              borderRadius: "5px",
            }}
            align="center"
          >
            {CartData.price}$
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography
            style={{
              background: "#eef4f7",
              border: "1px solid #42a4dc",
              borderRadius: "5px",
            }}
            align="center"
          >
            {CartData.weight + "Kg"}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography
            style={{
              background: "#eef4f7",
              border: "1px solid #42a4dc",
              borderRadius: "5px",
            }}
            align="center"
            veelooka="itemQuantityInCart"
            veelookastate="textContent"
          >
            {itemQuantityInCart}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Box textAlign="center">
            <IconButton
              size="small"
              veelooka="trashButton"
              onClick={onTrashButtonClicked}
              style={{
                background: "#eef4f7",
                color: "#42a4dc",
                border: "1px solid",
              }}
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default CartItem;
