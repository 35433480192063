import React from "react";
import { useState, useContext } from "react";
import { OurContext } from "../context/context";
import { isValidStoreItemQuantityEntry, d } from "./inputValidators";
import { Button, Grid, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { createTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const StoreItem = ({ StoreItemName, OnChangeStoreItemData }) => {
  const theme = createTheme({
    palette: {
      primary: { main: "#42a4dc" },
    },
  });

  const [itemCountTextBox, setItemCountTextBox] = useState(0);
  const ourContext = useContext(OurContext);

  function OnChangeStoreItem(event) {
    let quantity = event.target.value;
    if (isValidQuantityEntry(quantity)) {
      setItemCountTextBox(event.target.value);
      if (event.key === "Enter") {
        updateCartItemData(event.target.value);
      }
    }
  }

  var isValidQuantityEntry = ourContext.bugGenerator.wrapWithBugs(
    StoreItemName + ".isValidQuantityEntry",
    isValidStoreItemQuantityEntry
  );

  function setItemCountIfValidQuantity(newValue) {
    if (isValidQuantityEntry(newValue)) {
      setItemCountTextBox(newValue);
    }
  }

  function onPlusButtonClicked() {
    setItemCountIfValidQuantity(parseIntOrDefault(itemCountTextBox, 0) + 1);
  }

  var onMinusButtonClicked = ourContext.bugGenerator.wrapWithBugs(
    StoreItemName + ".onMinusButtonClicked",
    () => {
      setItemCountTextBox((value) =>
        Math.max(0, parseIntOrDefault(itemCountTextBox, 0) - 1)
      );
    }
  );

  function onAddCartBtn(data) {
    updateCartItemData(itemCountTextBox);
  }

  function parseIntOrDefault(number, deFault) {
    return parseInt(number) || deFault;
  }

  function updateCartItemData(countValue) {
    //update state
    OnChangeStoreItemData(
      (currentValue) =>
        parseIntOrDefault(currentValue, 0) + parseIntOrDefault(countValue, 0)
    );
    let action = StoreItemName.toLowerCase();
    //after item has been added to cart, clear the box:
    setItemCountTextBox("");
  }

  return (
    <div veelooka={StoreItemName}>
      <h3>{StoreItemName}</h3>
      <Grid container spacing={2}>
        <Grid item xs={12} md={1}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <IconButton
              size="small"
              style={{
                backgroundColor: "#eef4f7",
                color: "#42a4dc",
                border: "1px solid",
              }}
              veelooka="minus"
              onClick={onMinusButtonClicked}
            >
              <RemoveIcon />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <TextField
              id="outlined-basic"
              size="small"
              variant="outlined"
              placeholder=""
              value={itemCountTextBox}
              veelookastate={itemCountTextBox}
              veelooka="input"
              inputProps={{ maxLength: 3 }}
              onChange={OnChangeStoreItem}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={1}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <IconButton
              size="small"
              veelooka="plus"
              style={{
                backgroundColor: "#eef4f7",
                color: "#42a4dc",
                border: "1px solid",
              }}
              onClick={onPlusButtonClicked}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Button
              variant="contained"
              onClick={onAddCartBtn}
              veelooka="addToCart"
              style={{ background: "#42a4dc" }}
            >
              Add to cart
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default StoreItem;
